import React from "react";
import { graphql, Link } from "gatsby";

export const query = graphql`
  {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            slug
          }
        }
      }
    }
  }
`;

const BlogOverview = ({ data }) => {
  return (
    <>
      <p>Here's the blog posts:</p>
      <ul>
        {data.allMarkdownRemark.edges.map((edge) => {
          return (
            <li key={edge.node.frontmatter.slug}>
              <Link to={'/blog' + edge.node.frontmatter.slug}>{edge.node.frontmatter.title}</Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default BlogOverview;